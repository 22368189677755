/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import ls from 'local-storage'

export default function NotFoundPage() {

  let theme = ls.get('theme')

  return (
    <>
    <Layout>
      <SEO title="Error" />      
      <div data-theme={theme} className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-primary sm:text-5xl">404</p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold tracking-tight sm:text-5xl">Page not found</h1>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Link
                  to="/"
                  className="inline-flex items-center text-black px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm bg-primary"
                >
                  Open App
                </Link>
                <Link
                  to="/get-started"
                  className="inline-flex items-center text-black px-4 py-2 border border-transparent text-sm font-medium rounded-md bg-secondary"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </main>
        </div>
      </div>
      </Layout>
    </>
  )
}